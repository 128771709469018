<template>
  <div>
    <b-row v-if="this.$route.name != 'report'">
      <b-col cols="12" class="mb-1">
        <b-tabs align="center">
          <b-tab
            v-for="(header, index) in filterLabels"
            :key="index"
            :title="header.label"
            @click="switchFilter(header.value)"
          />
        </b-tabs>
      </b-col>
    </b-row>
    <div v-if="this.$route.name != 'phone-book'">
      <div
        v-if="
          (this.$route.name == 'incoming-order-Outlet') |
            (this.$route.name == 'outgoing-order-Outlet') |
            (this.$route.name == 'incoming-order-singapore') |
            (this.$route.name == 'outgoing-order-singapore') |
            (this.$route.name == 'incoming-order-agrofun') |
            (this.$route.name == 'outgoing-order-agrofun') |
            (this.$route.name == 'incoming-order-agrobazaar') |
            (this.$route.name == 'outgoing-order-agrobazaar') |
            (this.$route.name == 'dhl-order')
        "
      >
        <span class="h1 text-primary font-large-1">
          Total Order: {{ this.orderTable.rows.length }}
        </span>
      </div>
      <div
        v-if="this.$route.name == 'report'"
        class="d-flex align-items-center"
      >
        <b-form-group label="Order Date From" class="d-flex">
          <flat-pickr
            v-model="date_start"
            :config="{
              dateFormat: 'Y-m-d',
              maxDate: date_end,
            }"
            class="form-control font-small-3"
            placeholder="Choose Date"
            readonly="readonly"
          />
        </b-form-group>
        <h5 class="mx-1 mt-1 d-flex align-items-center">to</h5>
        <b-form-group label="Order Date To" class="d-flex">
          <flat-pickr
            v-model="date_end"
            :config="{
              dateFormat: 'Y-m-d',
              minDate: date_start,
            }"
            class="form-control font-small-3"
            placeholder="Choose Date"
            readonly="readonly"
          />
        </b-form-group>
        <b-button
          variant="primary"
          class="btn-tour-skip mx-1 mt-1"
          @click="displayTable($route.name)"
        >
          <span class="mr-25 align-middle">Get Orders</span>
        </b-button>
      </div>
      <div
        v-else
        class="my-1 d-flex justify-content-between align-items-center"
      >
        <div>
          <b-button
            variant="gradient-primary"
            class="box-shadow-1 px-5"
            :disabled="!this.orderTable.rows.length"
            @click="DownloadDO()"
            >Download All Delivery Order</b-button
          >
        </div>
        <b-form inline>
          <flat-pickr
            v-model="dateFilterFrom"
            :config="{
              dateFormat: 'Y-m-d',
              defaultDate: 'today',
              maxDate: dateFilterTo,
            }"
            class="form-control font-small-2 w-auto mr-1"
            placeholder="Choose Date"
            @input="displayTable($route.name)"
          />
          <span class="mr-1"> to </span>
          <flat-pickr
            v-model="dateFilterTo"
            :config="{
              dateFormat: 'Y-m-d',
              defaultDate: 'today',
              minDate: dateFilterFrom,
            }"
            class="form-control font-small-2 w-auto"
            placeholder="Choose Date"
            @input="displayTable($route.name)"
          />
        </b-form>
      </div>
    </div>
    <DataTable
      :order-table="orderTable"
      @cancelOrder="cancelOrder"
      @updateOrderstatus="updateOrderstatus"
      @refreshOrder="refreshOrder"
      @generatedhl="generatedhl"
      @handleExport="handleExport"
      @handleExportReport="handleExportReport"
    />
  </div>
</template>

<script>
import axios from '@axios';
import { ValidationProvider } from 'vee-validate';
import flatPickr from 'vue-flatpickr-component';
import DataTable from './DataTable.vue';

export default {
  components: {
    DataTable,
    flatPickr,
    ValidationProvider,
  },

  data() {
    return {
      filterLabels: [
        { label: 'All', value: 'All' },
        { label: 'Active', value: 'Active' },
        { label: 'Assigned', value: 'Assigned' },
        { label: 'Scheduled', value: 'Scheduled' },
        { label: 'History', value: 'Delivered' },
        { label: 'Cancelled', value: 'Cancelled' },
        { label: 'Refund Request', value: 'Refund Request' },
        { label: 'Payment Failed', value: 'Payment Failed' },
      ],
      filter: 'All',
      route: '',
      user: {},
      address: '',
      date_end: new Date().toISOString().split('T')[0],
      date_start: new Date(Date.now() - 172800000).toISOString().split('T')[0],
      date: '',
      dateFormat: '',
      dateFilter: null,
      dateFilterFrom: null,
      dateFilterTo: null,
      orderTable: {
        columns: [],
        rows: [],
      },
      incomingOutlet: {
        columns: [
          {
            label: 'Order ID',
            field: 'id',
            tdClass: 'text-center',
          },
          {
            label: 'Order Type',
            field: 'order_type',
            tdClass: 'text-center',
          },
          {
            label: 'Postcode Origin',
            field: 'sender.address_postcode',
            tdClass: 'text-center',
          },
          {
            label: 'Customer Name',
            field: 'recipient.first_name',
            tdClass: 'text-center',
          },
          {
            label: 'Postcode Destination',
            field: 'recipient.address_postcode',
            tdClass: 'text-center',
          },
          {
            label: 'Delivery Date',
            field: 'delivery_date',
            tdClass: 'text-center',
          },
          {
            label: 'QTY',
            field: 'quantity',
            tdClass: 'text-center',
          },
          {
            label: 'Product Category',
            field: 'product_category',
            tdClass: 'text-center',
          },
          {
            label: 'Status',
            field: 'status',
            tdClass: 'text-center',
          },
          {
            label: 'Action',
            field: 'action',
            tdClass: 'text-center',
          },
        ],
        rows: [],
      },
      historyOutlet: {
        columns: [
          {
            label: 'Order ID',
            field: 'id',
            tdClass: 'text-center',
          },
          {
            label: 'Order Type',
            field: 'order_type',
            tdClass: 'text-center',
          },
          {
            label: 'Postcode Origin',
            field: 'sender.address_postcode',
            tdClass: 'text-center',
          },
          {
            label: 'Customer Name',
            field: 'recipient.first_name',
            tdClass: 'text-center',
          },
          {
            label: 'Postcode Destination',
            field: 'recipient.address_postcode',
            tdClass: 'text-center',
          },
          {
            label: 'Delivery Date',
            field: 'delivery_date',
            tdClass: 'text-center',
          },
          {
            label: 'QTY',
            field: 'quantity',
            tdClass: 'text-center',
          },
          {
            label: 'Product Category',
            field: 'product_category',
            tdClass: 'text-center',
          },
          {
            label: 'Status',
            field: 'status',
            tdClass: 'text-center',
          },
        ],
        rows: [],
      },
      incomingSingapore: {
        columns: [
          {
            label: 'Order ID',
            field: 'id',
            tdClass: 'text-center',
          },
          {
            label: 'Order Type',
            field: 'order_type',
            tdClass: 'text-center',
          },
          {
            label: 'Sender Name',
            field: 'sender.first_name',
            tdClass: 'text-center',
          },
          {
            label: 'Postcode Origin',
            field: 'sender.address_postcode',
            tdClass: 'text-center',
          },
          {
            label: 'Recipient Name',
            field: 'recipient.first_name',
            tdClass: 'text-center',
          },
          {
            label: 'Postcode Destination',
            field: 'recipient.address_postcode',
            tdClass: 'text-center',
          },
          {
            label: 'Pick Up Date',
            field: 'pickup_date',
            tdClass: 'text-center',
          },
          {
            label: 'QTY',
            field: 'quantity',
            tdClass: 'text-center',
          },
          {
            label: 'Product Category',
            field: 'product_category',
            tdClass: 'text-center',
          },
          {
            label: 'Status',
            field: 'status',
            tdClass: 'text-center',
          },
          {
            label: 'Action',
            field: 'action',
            tdClass: 'text-center',
          },
        ],
        rows: [],
      },
      incomingAgrofun: {
        columns: [
          {
            label: 'Order ID',
            field: 'id',
            tdClass: 'text-center',
          },
          {
            label: 'Order Type',
            field: 'order_type',
            tdClass: 'text-center',
          },
          {
            label: 'Sender Name',
            field: 'sender.first_name',
            tdClass: 'text-center',
          },
          {
            label: 'Postcode Origin',
            field: 'sender.address_postcode',
            tdClass: 'text-center',
          },
          {
            label: 'Recipient Name',
            field: 'recipient.first_name',
            tdClass: 'text-center',
          },
          {
            label: 'Postcode Destination',
            field: 'recipient.address_postcode',
            tdClass: 'text-center',
          },
          {
            label: 'Pick Up Date',
            field: 'pickup_date',
            tdClass: 'text-center',
          },
          {
            label: 'QTY',
            field: 'quantity',
            tdClass: 'text-center',
          },
          {
            label: 'Product Category',
            field: 'product_category',
            tdClass: 'text-center',
          },
          {
            label: 'Status',
            field: 'status',
            tdClass: 'text-center',
          },
          {
            label: 'Action',
            field: 'action',
            tdClass: 'text-center',
          },
        ],
        rows: [],
      },
      incomingAgrobazaar: {
        columns: [
          {
            label: 'Order ID',
            field: 'id',
            tdClass: 'text-center',
          },
          {
            label: 'Order Type',
            field: 'order_type',
            tdClass: 'text-center',
          },
          {
            label: 'Sender Name',
            field: 'sender.first_name',
            tdClass: 'text-center',
          },
          {
            label: 'Postcode Origin',
            field: 'sender.address_postcode',
            tdClass: 'text-center',
          },
          {
            label: 'Recipient Name',
            field: 'recipient.first_name',
            tdClass: 'text-center',
          },
          {
            label: 'Postcode Destination',
            field: 'recipient.address_postcode',
            tdClass: 'text-center',
          },
          {
            label: 'Pick Up Date',
            field: 'pickup_date',
            tdClass: 'text-center',
          },
          {
            label: 'QTY',
            field: 'quantity',
            tdClass: 'text-center',
          },
          {
            label: 'Product Category',
            field: 'product_category',
            tdClass: 'text-center',
          },
          {
            label: 'Status',
            field: 'status',
            tdClass: 'text-center',
          },
          {
            label: 'Action',
            field: 'action',
            tdClass: 'text-center',
          },
        ],
        rows: [],
      },
      outgoingOutlet: {
        columns: [
          {
            label: 'Order ID',
            field: 'id',
            tdClass: 'text-center',
          },
          {
            label: 'Order Type',
            field: 'order_type',
            tdClass: 'text-center',
          },
          {
            label: 'Postcode Origin',
            field: 'sender.address_postcode',
            tdClass: 'text-center',
          },
          {
            label: 'Postcode Destination',
            field: 'recipient.address_postcode',
            tdClass: 'text-center',
          },
          {
            label: 'Delivery Date',
            field: 'delivery_date',
            tdClass: 'text-center',
          },
          {
            label: 'QTY',
            field: 'quantity',
            tdClass: 'text-center',
          },
          {
            label: 'Product Category',
            field: 'product_category',
            tdClass: 'text-center',
          },
          {
            label: 'Status',
            field: 'status',
            tdClass: 'text-center',
          },
          {
            label: 'Action',
            field: 'action',
            tdClass: 'text-center',
          },
        ],
        rows: [],
      },
      refundOutlet: {
        columns: [
          {
            label: 'Order ID',
            field: 'id',
            tdClass: 'text-center',
          },
          {
            label: 'Delivery Date',
            field: 'delivery_date',
            tdClass: 'text-center',
          },
          {
            label: 'Request Date',
            field: 'date_requested',
            tdClass: 'text-center',
          },
          {
            label: 'Date Refunded',
            field: 'date_of_approval_or_declined',
            tdClass: 'text-center',
          },
          {
            label: 'Postcode Origin',
            field: 'postcode_origin',
            tdClass: 'text-center',
          },
          {
            label: 'Postcode Destination',
            field: 'postcode_destination',
            tdClass: 'text-center',
          },
          {
            label: 'Amount',
            field: 'amount',
            tdClass: 'text-center',
          },
          {
            label: 'Status',
            field: 'refund_status',
            tdClass: 'text-center',
          },
          {
            label: 'Action',
            field: 'action',
            tdClass: 'text-center',
          },
        ],
        rows: [],
      },
      outgoingSingapore: {
        columns: [
          {
            label: 'Order ID',
            field: 'id',
            tdClass: 'text-center',
          },
          {
            label: 'Order Type',
            field: 'order_type',
            tdClass: 'text-center',
          },
          {
            label: 'Postcode Origin',
            field: 'sender.address_postcode',
            tdClass: 'text-center',
          },
          {
            label: 'Postcode Destination',
            field: 'recipient.address_postcode',
            tdClass: 'text-center',
          },
          {
            label: 'Pick Up Date',
            field: 'pickup_date',
            tdClass: 'text-center',
          },
          {
            label: 'QTY',
            field: 'quantity',
            tdClass: 'text-center',
          },
          {
            label: 'Product Category',
            field: 'product_category',
            tdClass: 'text-center',
          },
          {
            label: 'Status',
            field: 'status',
            tdClass: 'text-center',
          },
          {
            label: 'Action',
            field: 'action',
            tdClass: 'text-center',
          },
        ],
        rows: [],
      },
      outgoingAgrofun: {
        columns: [
          {
            label: 'Order ID',
            field: 'id',
            tdClass: 'text-center',
          },
          {
            label: 'Order Type',
            field: 'order_type',
            tdClass: 'text-center',
          },
          {
            label: 'Postcode Origin',
            field: 'sender.address_postcode',
            tdClass: 'text-center',
          },
          {
            label: 'Postcode Destination',
            field: 'recipient.address_postcode',
            tdClass: 'text-center',
          },
          {
            label: 'Pick Up Date',
            field: 'pickup_date',
            tdClass: 'text-center',
          },
          {
            label: 'QTY',
            field: 'quantity',
            tdClass: 'text-center',
          },
          {
            label: 'Product Category',
            field: 'product_category',
            tdClass: 'text-center',
          },
          {
            label: 'Status',
            field: 'status',
            tdClass: 'text-center',
          },
          {
            label: 'Action',
            field: 'action',
            tdClass: 'text-center',
          },
        ],
        rows: [],
      },
      outgoingAgrobazaar: {
        columns: [
          {
            label: 'Order ID',
            field: 'id',
            tdClass: 'text-center',
          },
          {
            label: 'Order Type',
            field: 'order_type',
            tdClass: 'text-center',
          },
          {
            label: 'Postcode Origin',
            field: 'sender.address_postcode',
            tdClass: 'text-center',
          },
          {
            label: 'Postcode Destination',
            field: 'recipient.address_postcode',
            tdClass: 'text-center',
          },
          {
            label: 'Pick Up Date',
            field: 'pickup_date',
            tdClass: 'text-center',
          },
          {
            label: 'QTY',
            field: 'quantity',
            tdClass: 'text-center',
          },
          {
            label: 'Product Category',
            field: 'product_category',
            tdClass: 'text-center',
          },
          {
            label: 'Status',
            field: 'status',
            tdClass: 'text-center',
          },
          {
            label: 'Action',
            field: 'action',
            tdClass: 'text-center',
          },
        ],
        rows: [],
      },
      DHL: {
        columns: [
          {
            label: 'Order ID',
            field: 'id',
            tdClass: 'text-center',
          },
          {
            label: 'Order Type',
            field: 'order_type',
            tdClass: 'text-center',
          },
          {
            label: 'Receiver Name',
            field: 'recipient.first_name',
            tdClass: 'text-center',
          },
          {
            label: 'Receiver Address',
            field: 'recipient.address_unit',
            tdClass: 'text-center',
          },
          {
            label: 'Postcode',
            field: 'recipient.address_postcode',
            tdClass: 'text-center',
          },
          {
            label: 'QTY',
            field: 'quantity',
            tdClass: 'text-center',
          },
          {
            label: 'Pick Up Date',
            field: 'pickup_date',
            tdClass: 'text-center',
          },
          {
            label: 'Status',
            field: 'status',
            tdClass: 'text-center',
          },
          {
            label: 'Action',
            field: 'action',
            tdClass: 'text-center',
          },
        ],
        rows: [],
      },
      transaction: {
        columns: [
          {
            label: 'Order ID',
            field: 'id',
          },
          {
            label: 'Order Date',
            field: 'order_placed_date',
          },
          {
            label: 'Delivery Date',
            field: 'delivery_date',
          },
          {
            label: 'Pickup Date',
            field: 'pickup_date',
          },
          {
            label: 'Shipment',
            field: 'shipment',
          },
          {
            label: "Sender's Name",
            field: 'sender_name',
          },
          {
            label: "Recipient's Name",
            field: 'recipient_name',
          },
          {
            label: 'Origin Postcode',
            field: 'sender_postcode',
          },
          {
            label: 'Destination Postcode',
            field: 'destination_postcode',
          },
          {
            label: 'QTY',
            field: 'qty',
          },
          {
            label: 'Tax',
            field: 'tax',
          },
          {
            label: 'Grand Total',
            field: 'amount',
          },
          {
            label: 'Payment Method',
            field: 'payment_method',
          },
        ],
        rows: [],
      },
      report: {
        columns: [
          {
            label: 'Order ID',
            field: 'id',
          },
          {
            label: 'Order Date',
            field: 'created_at',
          },
          {
            label: 'Delivery Date',
            field: 'delivery_date',
          },
          {
            label: 'Shipment',
            field: 'shipment',
          },
          {
            label: 'Status',
            field: 'status',
          },
          {
            label: "Driver's Name",
            field: 'driver.name',
          },
          {
            label: "Sender's Name",
            field: 'sender_name',
          },
          {
            label: "Recipient's Name",
            field: 'recipient_name',
          },
          {
            label: 'Origin Postcode',
            field: 'sender_postcode',
          },
          {
            label: 'Destination Postcode',
            field: 'destination_postcode',
          },
          {
            label: 'QTY',
            field: 'qty',
          },
          {
            label: 'Sub Total',
            field: 'sub_total',
          },
          {
            label: 'Tax',
            field: 'tax',
          },
          {
            label: 'Grand Total',
            field: 'amount',
          },
          {
            label: 'Remarks',
            field: 'remarks',
          },
        ],
        rows: [],
      },
    };
  },

  watch: {
    $route(to, from) {
      this.displayTable(to.name);
    },
  },

  created() {
    this.dateFilterTo =
      this.dateFilterFrom =
      this.dateFilter =
        new Date().toISOString();
    const currentAddress = JSON.parse(localStorage.getItem('userData'));
    if (this.address != currentAddress.branch_id) {
      this.address = currentAddress.branch_id;
      this.displayTable(this.$route.name);
    } else {
      this.displayTable(this.$route.name);
    }
    this.user = JSON.parse(localStorage.getItem('userData'));
  },

  methods: {
    switchFilter(filter) {
      this.filter = filter;
      this.displayTable(this.$route.name);
    },
    refreshOrder() {
      this.displayTable(this.$route.name);
    },
    async displayTable(currentRoute) {
      this.orderTable = {
        columns: [],
        rows: [],
      };
      this.dateFormatFrom = this.dateFilterFrom.split('T')[0];
      this.dateFormatTo = this.dateFilterTo.split('T')[0];
      this.dateFormat = this.dateFilterTo.split('T')[0];
      switch (currentRoute) {
        case 'incoming-order-Outlet': {
          const incomingOutlet = await this.$http.get(
            `orders_range/outlet/${this.dateFormatFrom}/${this.dateFormatTo}/incoming?filter=${this.filter}`
          );
          if (incomingOutlet.status === 200) {
            this.incomingOutlet.rows = incomingOutlet.data.data;
          }
          this.orderTable = this.incomingOutlet;
          break;
        }
        case 'incoming-order-singapore': {
          const incomingSingapore = await this.$http.get(
            `orders_range/singapore/${this.dateFormatFrom}/${this.dateFormatTo}/incoming?filter=${this.filter}`
          );
          if (incomingSingapore.status === 200) {
            this.incomingSingapore.rows = incomingSingapore.data.data;
          }
          this.orderTable = this.incomingSingapore;
          break;
        }
        case 'incoming-order-agrofun': {
          const incomingAgrofun = await this.$http.get(
            `orders_range/agrofun/${this.dateFormatFrom}/${this.dateFormatTo}/incoming?filter=${this.filter}`
          );
          if (incomingAgrofun.status === 200) {
            this.incomingAgrofun.rows = incomingAgrofun.data.data;
          }
          this.orderTable = this.incomingAgrofun;
          break;
        }
        case 'incoming-order-agrobazaar': {
          const incomingAgrobazaar = await this.$http.get(
            `orders_range/agrobazaar/${this.dateFormatFrom}/${this.dateFormatTo}/incoming?filter=${this.filter}`
          );
          if (incomingAgrobazaar.status === 200) {
            this.incomingAgrobazaar.rows = incomingAgrobazaar.data.data;
          }
          this.orderTable = this.incomingAgrobazaar;
          break;
        }
        case 'outgoing-order-Outlet': {
          if (this.filter == 'Refund Request') {
            const outgoingOutlet = await this.$http.get('refund');
            if (outgoingOutlet.status === 200) {
              this.refundOutlet.rows = outgoingOutlet.data.data;
            }
            this.orderTable = this.refundOutlet;
          } else if (this.filter != 'Payment Failed') {
            const outgoingOutlet = await this.$http.get(
              `orders_range/outlet/${this.dateFormatFrom}/${this.dateFormatTo}/outgoing?filter=${this.filter}`
            );
            if (outgoingOutlet.status === 200) {
              this.outgoingOutlet.rows = outgoingOutlet.data.data;
            }
            this.orderTable = this.outgoingOutlet;
          } else {
            const outgoingOutlet = await this.$http.get(
              `orders_range/outlet/${this.dateFormatFrom}/${this.dateFormatTo}/outgoing?filter=Active&payment_filter=Payment Declined`
            );
            if (outgoingOutlet.status === 200) {
              this.outgoingOutlet.rows = outgoingOutlet.data.data;
            }
            this.orderTable = this.outgoingOutlet;
          }
          if (this.filter == 'Delivered') {
            const historyOutlet = await this.$http.get(
              `orders_range/outlet/${this.dateFormatFrom}/${this.dateFormatTo}/outgoing?filter=${this.filter}`
            );
            if (historyOutlet.status === 200) {
              this.historyOutlet.rows = historyOutlet.data.data;
            }
            this.orderTable = this.historyOutlet;
          }
          break;
        }
        case 'outgoing-order-singapore': {
          if (this.filter != 'Payment Failed') {
            const outgoingSingapore = await this.$http.get(
              `orders_range/singapore/${this.dateFormatFrom}/${this.dateFormatTo}/outgoing?filter=${this.filter}`
            );
            if (outgoingSingapore.status === 200) {
              this.outgoingSingapore.rows = outgoingSingapore.data.data;
            }
          } else {
            const outgoingSingapore = await this.$http.get(
              `orders_range/singapore/${this.dateFormatFrom}/${this.dateFormatTo}/outgoing?filter=Active&payment_filter=Payment Declined`
            );
            if (outgoingSingapore.status === 200) {
              this.outgoingSingapore.rows = outgoingSingapore.data.data;
            }
          }
          this.orderTable = this.outgoingSingapore;
          break;
        }
        case 'outgoing-order-agrofun': {
          if (this.filter != 'Payment Failed') {
            const outgoingAgrofun = await this.$http.get(
              `orders_range/agrofun/${this.dateFormatFrom}/${this.dateFormatTo}/outgoing?filter=${this.filter}`
            );
            if (outgoingAgrofun.status === 200) {
              this.outgoingAgrofun.rows = outgoingAgrofun.data.data;
            }
          } else {
            const outgoingAgrofun = await this.$http.get(
              `orders_range/agrofun/${this.dateFormatFrom}/${this.dateFormatTo}/outgoing?filter=Active&payment_filter=Payment Declined`
            );
            if (outgoingAgrofun.status === 200) {
              this.outgoingAgrofun.rows = outgoingAgrofun.data.data;
            }
          }
          this.orderTable = this.outgoingAgrofun;
          break;
        }
        case 'outgoing-order-agrobazaar': {
          if (this.filter != 'Payment Failed') {
            const outgoingAgrobazaar = await this.$http.get(
              `orders_range/agrobazaar/${this.dateFormatFrom}/${this.dateFormatTo}/outgoing?filter=${this.filter}`
            );
            if (outgoingAgrobazaar.status === 200) {
              this.outgoingAgrobazaar.rows = outgoingAgrobazaar.data.data;
            }
          } else {
            const outgoingAgrobazaar = await this.$http.get(
              `orders_range/agrobazaar/${this.dateFormatFrom}/${this.dateFormatTo}/outgoing?filter=Active&payment_filter=Payment Declined`
            );
            if (outgoingAgrobazaar.status === 200) {
              this.outgoingAgrobazaar.rows = outgoingAgrobazaar.data.data;
            }
          }
          this.orderTable = this.outgoingAgrobazaar;
          break;
        }
        case 'dhl-order': {
          const dhl = await this.$http.get(
            `dhl_orders_range/${this.dateFormatFrom}/${this.dateFormatTo}?filter=${this.filter}`
          );
          if (dhl.status === 200) {
            this.DHL.rows = dhl.data.data;
          }
          this.orderTable = this.DHL;
          break;
        }
        case 'transaction': {
          const transaction = await this.$http.get(
            `transactions_list/${this.dateFormat}`
          );
          if (transaction.status === 200) {
            this.transaction.rows = transaction.data.data;
          }
          this.orderTable = this.transaction;
          break;
        }
        case 'report': {
          const report = await this.$http.get(
            `report/master_list/${this.date_start}/${this.date_end}`
          );
          if (report.status === 200) {
            this.report.rows = report.data.data;
          }
          this.orderTable = this.report;
          break;
        }
        case 'phone-book':
          this.orderTable = this.phone;
          break;
        default:
          break;
      }
    },
    cancelOrder(orderCancel, index) {
      axios;
      this.$http.post('cancel_order', orderCancel).then((response) => {
        if (response.status == 200) {
          if (response.data.status == true) {
            this.$swal({
              title: 'Cancellation Accepted !',
              icon: 'success',
              text: 'Successfully cancelled the order',
              confirmButtonText: 'OK',
              customClass: {
                confirmButton: 'btn btn-success',
              },
              buttonsStyling: false,
            });
            this.orderTable.rows[index].status = 'Cancelled';
          } else {
            this.$swal({
              title: 'Cancellation Error !',
              icon: 'error',
              text: response.data.message,
              confirmButtonText: 'Confirm',
              customClass: {
                confirmButton: 'btn btn-warning',
              },
              buttonsStyling: false,
            });
          }
        }
      });
    },
    updateOrderstatus(updateStatus, index) {
      this.$http.post('update_order_status', updateStatus).then((response) => {
        if (response.status === 200) {
          if (response.data.status == true) {
            this.$swal({
              title: 'Status Delivery Updated !',
              text: response.data.message,
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            });
            this.orderTable.rows[index].status = updateStatus.status;
          } else {
            this.$swal({
              title: 'Status Delivery Error !',
              text: response.data.message,
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            });
          }
        }
      });
    },
    generatedhl(id, index) {
      this.$http.get(`generate/${id}`).then((response) => {
        if (response.status == 200) {
          if (response.data.status == true) {
            this.$swal({
              title: 'DHL Generated !',
              text: response.data.message,
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            });
            this.orderTable.rows[index].status = 'Generated';
            this.$http.get(`dhl_orders/${this.dateFormat}`).then((response) => {
              if (response.status === 200) {
                this.DHL.rows = response.data.data;
              }
              this.orderTable = this.DHL;
            });
          } else {
            this.$swal({
              title: 'Error !',
              text: response.data.message,
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            });
          }
        }
      });
    },
    async DownloadDO() {
      let order_type = '';
      if (
        this.$route.name === 'outgoing-order-Outlet' ||
        this.$route.name === 'incoming-order-Outlet'
      ) {
        order_type = 'Outlet';
      } else if (
        this.$route.name === 'outgoing-order-singapore' ||
        this.$route.name === 'incoming-order-singapore'
      ) {
        order_type = 'Singapore';
      } else if (
        this.$route.name === 'outgoing-order-agrofun' ||
        this.$route.name === 'incoming-order-agrofun'
      ) {
        order_type = 'Agrofun';
      } else if (
        this.$route.name === 'outgoing-order-agrobazaar' ||
        this.$route.name === 'incoming-order-agrobazaar'
      ) {
        order_type = 'Agrobazaar';
      } else {
        order_type = 'DHL';
      }
      if (
        this.$route.name === 'incoming-order-singapore' ||
        this.$route.name === 'incoming-order-Outlet' ||
        this.$route.name === 'incoming-order-agrofun' ||
        this.$route.name === 'incoming-order-agrobazaar'
      ) {
        const response = await this.$http.get(
          `incomingOrdersBulkGenerateLabel?order_type=${order_type}&date=${this.dateFormat}`,
          { responseType: 'blob' }
        );
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement('a');

        fileLink.href = fileURL;
        fileLink.setAttribute('download', 'DeliveryOrders.pdf');
        document.body.appendChild(fileLink);

        fileLink.click();
      } else {
        const response = await this.$http.get(
          `bulkGenerateLabel?order_type=${order_type}&date=${this.dateFormat}`,
          { responseType: 'blob' }
        );
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement('a');

        fileLink.href = fileURL;
        fileLink.setAttribute('download', 'DeliveryOrders.pdf');
        document.body.appendChild(fileLink);

        fileLink.click();
      }
    },
    async handleExportReport() {
      const response = await this.$http.get(
        `export_report/master_list/${this.date_start}/${this.date_end}/master/${this.user.branch_id}`,
        { responseType: 'blob' }
      );
      const fileURL = window.URL.createObjectURL(new Blob([response.data]));
      const fileLink = document.createElement('a');

      fileLink.href = fileURL;
      fileLink.setAttribute(
        'download',
        `MasterReports_${this.date_start}-${this.date_end}.xlsx`
      );
      document.body.appendChild(fileLink);

      fileLink.click();
    },

    async handleExport() {
      if (
        (this.$route.name == 'incoming-order-Outlet') |
        (this.$route.name == 'outgoing-order-Outlet')
      ) {
        this.route = 'Outlet';
      } else if (
        (this.$route.name == 'incoming-order-singapore') |
        (this.$route.name == 'outgoing-order-singapore')
      ) {
        this.route = 'Singapore';
      } else if (
        this.$route.name === 'outgoing-order-agrofun' ||
        this.$route.name === 'incoming-order-agrofun'
      ) {
        this.route = 'Agrofun';
      } else if (
        this.$route.name === 'outgoing-order-agrobazaar' ||
        this.$route.name === 'incoming-order-agrobazaar'
      ) {
        this.route = 'Agrobazaar';
      } else {
        this.route = 'DHL';
      }
      if (this.route == 'DHL') {
        const response = await this.$http.get(
          `orders_export/${this.route}/${this.dateFormat}`,
          { responseType: 'blob' }
        );
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement('a');

        fileLink.href = fileURL;
        fileLink.setAttribute(
          'download',
          `${this.route}Order_${this.dateFilter}.xlsx`
        );
        document.body.appendChild(fileLink);

        fileLink.click();
      } else {
        const response = await this.$http.get(
          `orders_export/${this.route}/${this.dateFormat}/${this.filter}`,
          { responseType: 'blob' }
        );
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement('a');

        fileLink.href = fileURL;
        fileLink.setAttribute(
          'download',
          `${this.filter}${this.route}Order_${this.dateFilter}.xlsx`
        );
        document.body.appendChild(fileLink);

        fileLink.click();
      }
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
