var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(this.$route.name != 'report')?_c('b-row',[_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12"}},[_c('b-tabs',{attrs:{"align":"center"}},_vm._l((_vm.filterLabels),function(header,index){return _c('b-tab',{key:index,attrs:{"title":header.label},on:{"click":function($event){return _vm.switchFilter(header.value)}}})}),1)],1)],1):_vm._e(),(this.$route.name != 'phone-book')?_c('div',[(
        (this.$route.name == 'incoming-order-Outlet') |
          (this.$route.name == 'outgoing-order-Outlet') |
          (this.$route.name == 'incoming-order-singapore') |
          (this.$route.name == 'outgoing-order-singapore') |
          (this.$route.name == 'incoming-order-agrofun') |
          (this.$route.name == 'outgoing-order-agrofun') |
          (this.$route.name == 'incoming-order-agrobazaar') |
          (this.$route.name == 'outgoing-order-agrobazaar') |
          (this.$route.name == 'dhl-order')
      )?_c('div',[_c('span',{staticClass:"h1 text-primary font-large-1"},[_vm._v(" Total Order: "+_vm._s(this.orderTable.rows.length)+" ")])]):_vm._e(),(this.$route.name == 'report')?_c('div',{staticClass:"d-flex align-items-center"},[_c('b-form-group',{staticClass:"d-flex",attrs:{"label":"Order Date From"}},[_c('flat-pickr',{staticClass:"form-control font-small-3",attrs:{"config":{
            dateFormat: 'Y-m-d',
            maxDate: _vm.date_end,
          },"placeholder":"Choose Date","readonly":"readonly"},model:{value:(_vm.date_start),callback:function ($$v) {_vm.date_start=$$v},expression:"date_start"}})],1),_c('h5',{staticClass:"mx-1 mt-1 d-flex align-items-center"},[_vm._v("to")]),_c('b-form-group',{staticClass:"d-flex",attrs:{"label":"Order Date To"}},[_c('flat-pickr',{staticClass:"form-control font-small-3",attrs:{"config":{
            dateFormat: 'Y-m-d',
            minDate: _vm.date_start,
          },"placeholder":"Choose Date","readonly":"readonly"},model:{value:(_vm.date_end),callback:function ($$v) {_vm.date_end=$$v},expression:"date_end"}})],1),_c('b-button',{staticClass:"btn-tour-skip mx-1 mt-1",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.displayTable(_vm.$route.name)}}},[_c('span',{staticClass:"mr-25 align-middle"},[_vm._v("Get Orders")])])],1):_c('div',{staticClass:"my-1 d-flex justify-content-between align-items-center"},[_c('div',[_c('b-button',{staticClass:"box-shadow-1 px-5",attrs:{"variant":"gradient-primary","disabled":!this.orderTable.rows.length},on:{"click":function($event){return _vm.DownloadDO()}}},[_vm._v("Download All Delivery Order")])],1),_c('b-form',{attrs:{"inline":""}},[_c('flat-pickr',{staticClass:"form-control font-small-2 w-auto mr-1",attrs:{"config":{
            dateFormat: 'Y-m-d',
            defaultDate: 'today',
            maxDate: _vm.dateFilterTo,
          },"placeholder":"Choose Date"},on:{"input":function($event){return _vm.displayTable(_vm.$route.name)}},model:{value:(_vm.dateFilterFrom),callback:function ($$v) {_vm.dateFilterFrom=$$v},expression:"dateFilterFrom"}}),_c('span',{staticClass:"mr-1"},[_vm._v(" to ")]),_c('flat-pickr',{staticClass:"form-control font-small-2 w-auto",attrs:{"config":{
            dateFormat: 'Y-m-d',
            defaultDate: 'today',
            minDate: _vm.dateFilterFrom,
          },"placeholder":"Choose Date"},on:{"input":function($event){return _vm.displayTable(_vm.$route.name)}},model:{value:(_vm.dateFilterTo),callback:function ($$v) {_vm.dateFilterTo=$$v},expression:"dateFilterTo"}})],1)],1)]):_vm._e(),_c('DataTable',{attrs:{"order-table":_vm.orderTable},on:{"cancelOrder":_vm.cancelOrder,"updateOrderstatus":_vm.updateOrderstatus,"refreshOrder":_vm.refreshOrder,"generatedhl":_vm.generatedhl,"handleExport":_vm.handleExport,"handleExportReport":_vm.handleExportReport}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }