<template>
  <div>
    <b-card>
      <div class="custom-search d-flex justify-content-between mt-1">
        <b-button
          v-if="this.$route.name == 'dhl-order'"
          variant="gradient-primary"
          class="box-shadow-1 h-50"
          href="/dhl-order/create"
        >
          <span class=""> CREATE ORDER</span>
          <feather-icon
            icon="PlusCircleIcon"
            size="14"
            class="text-white ml-1"
          />
        </b-button>
        <b-button
          v-else-if="this.$route.name == 'report'"
          variant="outline-primary"
          class="btn-tour-skip my-1"
          @click="handleExportReport()"
        >
          <span class="mr-25 align-middle">Extract Orders</span>
        </b-button>

        <b-button
          v-else
          variant="outline-primary"
          class="btn-tour-skip my-1"
          @click="handleExport()"
        >
          <span class="mr-25 align-middle">Extract Orders</span>
        </b-button>
        <b-form-group>
          <div
            class="row row-cols-2 d-flex justify-content-center align-items-center"
          >
            <div class="col-sm-12 col-lg-12 col-md-12">
              <!-- search input -->
              <div class="custom-search d-flex justify-content-end">
                <b-form-group>
                  <div class="d-flex align-items-center">
                    <b-form-input
                      v-model="searchTerm"
                      placeholder="Search all field"
                      type="text"
                      class="d-inline-block"
                    />
                  </div>
                </b-form-group>
              </div>
            </div>
          </div>
        </b-form-group>
      </div>
      <div v-if="this.$route.name == 'dhl-order'">
        <vue-good-table
          :columns="orderTable.columns"
          :rows="orderTable.rows"
          :rtl="direction"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm,
          }"
          :pagination-options="{
            enabled: true,
            perPage: pageLength,
          }"
          style-class="vgt-table"
        >
          <div slot="emptystate">No data to show !</div>
          <template slot="table-row" slot-scope="props">
            <template
              v-if="
                props.column.field === 'id' ||
                props.column.field === 'status' ||
                props.column.field === 'action' ||
                props.column.field === 'pickup_date' ||
                props.column.field === 'recipient.first_name' ||
                props.column.field === 'recipient.address_unit'
              "
            >
              <span v-if="props.column.field == 'id'">
                <b-button
                  variant="gradient-primary"
                  block
                  @click="editOrder(props.row.id)"
                  >{{ props.row.id }}</b-button
                >
              </span>
              <span v-if="props.column.field == 'pickup_date'"
                >{{ props.row.pickup_date }}
              </span>
              <span v-if="props.column.field == 'recipient.first_name'">
                {{ props.row.recipient.first_name }}
                {{ props.row.recipient.last_name }}
              </span>
              <span v-if="props.column.field == 'recipient.address_unit'"
                >{{ props.row.recipient.address_unit }}
                {{ props.row.recipient.address_line_1 }}
                {{ props.row.recipient.address_line_2 }}
                {{ props.row.recipient.address_area }}
                {{ props.row.recipient.address_state }}
                {{ props.row.recipient.address_country }}
              </span>
              <span v-if="props.column.field == 'status'">
                <b-button
                  v-if="props.row.status === 'Active'"
                  block
                  variant="success"
                >
                  Active
                </b-button>
                <b-button
                  v-else-if="props.row.status === 'Generated'"
                  block
                  variant="warning"
                >
                  Generated
                </b-button>
                <b-button v-else block variant="danger"> Cancelled </b-button>
              </span>
              <span v-if="props.column.field === 'action'">
                <b-dropdown
                  v-if="props.row.status === 'Active'"
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item
                    @click="generatedhl(props.row.id, props.row.originalIndex)"
                  >
                    <span>Generate Label</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    @click="cancelOrder(props.row.id, props.row.originalIndex)"
                  >
                    <span>Cancel</span>
                  </b-dropdown-item>
                </b-dropdown>
                <b-dropdown
                  v-else-if="props.row.status === 'Generated'"
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item
                    @click="generatedhllabel(props.row.labelUrl)"
                  >
                    <span>Download Label</span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="generatedhltracking(props.row)">
                    <span>DHL Tracking</span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="canceldhlgenerate(props.row)">
                    <span>Cancel</span>
                  </b-dropdown-item>
                </b-dropdown>
                <b-dropdown
                  v-else
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item>
                    <span>No actions available</span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
            </template>
          </template>
        </vue-good-table>
      </div>
      <div
        v-else-if="
          this.$route.name == 'outgoing-order-Outlet' ||
          this.$route.name == 'outgoing-order-singapore'
        "
      >
        <vue-good-table
          ref="my-table"
          :columns="orderTable.columns"
          :rows="orderTable.rows"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm,
          }"
          :pagination-options="{
            enabled: true,
            perPage: pageLength,
          }"
          :select-options="{
            enabled: true,
            selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: false, // disable the select info panel on top
            selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
          }"
          style-class="vgt-table"
          @on-selected-rows-change="selectionChanged"
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'id'">
              <b-button
                variant="gradient-primary"
                block
                @click="editOrder(props.row.id)"
                >{{
                  props.row.id ? props.row.id : props.row.order_id
                }}</b-button
              >
            </span>
            <span v-else-if="props.column.field === 'driver.name'">
              {{ props.row.driver ? props.row.driver.name : '-' }}
            </span>
            <span v-else-if="props.column.field == 'sender.first_name'">
              {{ props.row.sender ? props.row.sender.first_name : '-' }}
              {{ props.row.sender ? props.row.sender.last_name : '' }}
            </span>
            <span v-else-if="props.column.field == 'recipient.first_name'">
              {{ props.row.recipient ? props.row.recipient.first_name : '-' }}
              {{ props.row.recipient ? props.row.recipient.last_name : '' }}
            </span>
            <span v-else-if="props.column.field == 'sub_total'">
              {{ $helpers.formatTotal(props.row.sub_total) }}
            </span>
            <span v-else-if="props.column.field == 'amount'">
              {{ $helpers.formatTotal(props.row.grand_total) }}
            </span>
            <span v-else-if="props.column.field == 'costing.costing'">
              {{
                $helpers.formatTotal(
                  props.row.costing ? props.row.costing.costing : 0
                )
              }}
            </span>
            <span v-else-if="props.column.field == 'costing.sales'">
              {{
                $helpers.formatTotal(
                  props.row.costing ? props.row.costing.sales : 0
                )
              }}
            </span>
            <span v-else-if="props.column.field == 'costing.Outlet_profit'">
              {{
                $helpers.formatTotal(
                  props.row.costing ? props.row.costing.Outlet_profit : 0
                )
              }}
            </span>
            <span v-else-if="props.column.field == 'costing.commission'">
              {{
                $helpers.formatTotal(
                  props.row.costing ? props.row.costing.commission : 0
                )
              }}
            </span>
            <span v-else-if="props.column.field == 'tax'">
              {{ props.row.tax }}%
            </span>
            <span v-else-if="props.column.field == 'created_at'">
              {{ $helpers.formatDate(props.row.created_at) }}
            </span>
            <span v-else-if="props.column.field == 'pickup_date'">
              <span v-if="props.row.pickup_date">
                {{ $helpers.formatDate(props.row.pickup_date) }}
              </span>
              <span v-else>N/A</span>
            </span>
            <span v-else-if="props.column.field == 'delivery_date'">
              <span v-if="props.row.delivery_date">
                {{ $helpers.formatDate(props.row.delivery_date) }}
              </span>
              <span v-else>N/A</span>
            </span>
            <span v-else-if="props.column.field == 'order_placed_date'">
              {{ $helpers.formatDate(props.row.order_placed_date) }}
            </span>
            <span v-else-if="props.column.field == 'status'">
              <button
                v-if="props.row.status === 'Active'"
                block
                class="btn btn-block Active"
              >
                {{ props.row.status }}
              </button>
              <button
                v-else-if="props.row.status === 'Assigned'"
                block
                class="btn btn-block Assigned"
              >
                {{ props.row.status }}
              </button>
              <button
                v-if="props.row.status === 'In Warehouse'"
                block
                class="btn btn-block InWarehouse"
              >
                {{ props.row.status }}
              </button>
              <button
                v-else-if="props.row.status === 'In Transit'"
                block
                class="btn btn-block InTransit"
              >
                {{ props.row.status }}
              </button>
              <button
                v-if="props.row.status === 'Out For Delivery'"
                block
                class="btn btn-block OutForDelivery"
              >
                {{ props.row.status }}
              </button>
              <button
                v-else-if="props.row.status === 'Delivered'"
                block
                class="btn btn-block Delivered"
              >
                {{ props.row.status }}
              </button>
              <button
                v-else-if="
                  props.row.status === 'Close Store' ||
                  props.row.status === 'Assigned For Last Mile' ||
                  props.row.status === 'Arrived At Branch'
                "
                block
                class="btn btn-block CloseStoreDeliveryFailed"
              >
                {{ props.row.status }}
              </button>
              <button
                v-else-if="props.row.status === 'Second Delivery'"
                block
                class="btn btn-block SecondDelivery"
              >
                {{ props.row.status }}
              </button>
              <b-button
                v-else-if="props.row.status === 'Cancelled'"
                block
                variant="danger"
              >
                {{ props.row.status }}
              </b-button>
              <b-button
                v-else-if="props.row.status === 'Generated'"
                block
                variant="warning"
              >
                Generated
              </b-button>
              <b-button
                v-else-if="props.row.status === 'Payment Failed'"
                block
                variant="warning"
              >
                {{ props.row.status }}
              </b-button>
            </span>
            <span v-else-if="props.column.field == 'order_type'">
              <b-button
                v-if="props.row.order_type == 'DHL'"
                variant="warning"
                block
                >{{ props.row.order_type }}</b-button
              >
              <b-button
                v-if="props.row.order_type == 'Outlet'"
                variant="info"
                block
                >{{ props.row.order_type }}</b-button
              >
              <b-button
                v-if="props.row.order_type == 'Singapore'"
                variant="success"
                block
                >{{ props.row.order_type }}</b-button
              >
            </span>
            <span v-else-if="props.column.field === 'action'">
              <b-dropdown
                v-if="props.row.payment_status != 'Payment Declined'"
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    v-if="props.row.status != 'Delivered'"
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item @click="generateLabel(props.row)">
                  <span>Generate Label</span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="
                    updateOrderStatus(props.row.id, props.row.originalIndex)
                  "
                >
                  <span>Update Status</span>
                </b-dropdown-item>
                <b-dropdown-item
                  :to="{ name: 'track-order', params: { id: props.row.id } }"
                >
                  <span>View Tracking</span>
                </b-dropdown-item>
                <b-dropdown-item @click="editOrder(props.row.id)">
                  <span>Edit Order</span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="cancelOrder(props.row.id, props.row.originalIndex)"
                >
                  <span>Cancel</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="props.row.status === 'Cancelled'"
                >
                  <span>Request for Refund</span>
                </b-dropdown-item>

                <!-- <b-dropdown-item @click="getDriver(props.row.id)">
                  <span>Assign Driver</span>
                </b-dropdown-item> -->
              </b-dropdown>
              <b-dropdown
                v-else
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item>
                  <span>Pay Now</span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="cancelOrder(props.row.id, props.row.originalIndex)"
                >
                  <span>Delete Order</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>

            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap"> Showing 1 to </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['5', '10', '20']"
                  class="mx-1"
                  @input="
                    (value) => props.perPageChanged({ currentPerPage: value })
                  "
                />
                <span class="text-nowrap"> of {{ props.total }} entries </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value) => props.pageChanged({ currentPage: value })"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
          <div
            v-if="
              orderTable.rows[0] &&
              orderTable.rows[0].status != 'Payment Failed' &&
              !orderTable.rows[0].refund_status
            "
            slot="selected-row-actions"
          >
            <b-button v-b-modal.modal-1 variant="primary"
              >Transit to Other Branch</b-button
            >
          </div>
        </vue-good-table>
      </div>
      <div v-else>
        <vue-good-table
          :columns="orderTable.columns"
          :rows="orderTable.rows"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm,
          }"
          :pagination-options="{
            enabled: true,
            perPage: pageLength,
          }"
          style-class="vgt-table"
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'id'">
              <b-button
                variant="gradient-primary"
                block
                @click="editOrder(props.row.id)"
                >{{ props.row.id }}</b-button
              >
            </span>
            <span v-else-if="props.column.field === 'shipment'">
              {{
                props.row.shipment === 'Outlet' ? 'Outlet' : props.row.shipment
              }}
            </span>
            <span v-else-if="props.column.field === 'driver.name'">
              {{ props.row.driver ? props.row.driver.name : '-' }}
            </span>
            <span v-else-if="props.column.field == 'sender.first_name'">
              {{ props.row.sender ? props.row.sender.first_name : '-' }}
              {{ props.row.sender ? props.row.sender.last_name : '' }}
            </span>
            <span v-else-if="props.column.field == 'recipient.first_name'">
              {{ props.row.recipient ? props.row.recipient.first_name : '-' }}
              {{ props.row.recipient ? props.row.recipient.last_name : '' }}
            </span>
            <span v-else-if="props.column.field == 'sub_total'">
              {{ $helpers.formatTotal(props.row.sub_total) }}
            </span>
            <span v-else-if="props.column.field == 'amount'">
              {{ $helpers.formatTotal(props.row.grand_total) }}
            </span>
            <span v-else-if="props.column.field == 'costing.costing'">
              {{
                $helpers.formatTotal(
                  props.row.costing ? props.row.costing.costing : 0
                )
              }}
            </span>
            <span v-else-if="props.column.field == 'costing.sales'">
              {{
                $helpers.formatTotal(
                  props.row.costing ? props.row.costing.sales : 0
                )
              }}
            </span>
            <span v-else-if="props.column.field == 'costing.Outlet_profit'">
              {{
                $helpers.formatTotal(
                  props.row.costing ? props.row.costing.Outlet_profit : 0
                )
              }}
            </span>
            <span v-else-if="props.column.field == 'costing.commission'">
              {{
                $helpers.formatTotal(
                  props.row.costing ? props.row.costing.commission : 0
                )
              }}
            </span>
            <span v-else-if="props.column.field == 'tax'">
              {{ props.row.tax }}%
            </span>
            <span v-else-if="props.column.field == 'created_at'">
              {{ $helpers.formatDate(props.row.created_at) }}
            </span>
            <span v-else-if="props.column.field == 'pickup_date'">
              <span v-if="props.row.pickup_date">
                {{ $helpers.formatDate(props.row.pickup_date) }}
              </span>
              <span v-else>N/A</span>
            </span>
            <span v-else-if="props.column.field == 'delivery_date'">
              <span v-if="props.row.delivery_date">
                {{ $helpers.formatDate(props.row.delivery_date) }}
              </span>
              <span v-else>N/A</span>
            </span>
            <span v-else-if="props.column.field == 'order_placed_date'">
              {{ $helpers.formatDate(props.row.order_placed_date) }}
            </span>
            <span v-else-if="props.column.field == 'status'">
              <button
                v-if="props.row.status === 'Active'"
                block
                class="btn btn-block Active"
              >
                {{ props.row.status }}
              </button>
              <button
                v-else-if="props.row.status === 'Assigned'"
                block
                class="btn btn-block Assigned"
              >
                {{ props.row.status }}
              </button>
              <button
                v-if="props.row.status === 'In Warehouse'"
                block
                class="btn btn-block InWarehouse"
              >
                {{ props.row.status }}
              </button>
              <button
                v-else-if="props.row.status === 'In Transit'"
                block
                class="btn btn-block InTransit"
              >
                {{ props.row.status }}
              </button>
              <button
                v-if="props.row.status === 'Out For Delivery'"
                block
                class="btn btn-block OutForDelivery"
              >
                {{ props.row.status }}
              </button>
              <button
                v-else-if="props.row.status === 'Delivered'"
                block
                class="btn btn-block Delivered"
              >
                {{ props.row.status }}
              </button>
              <button
                v-else-if="
                  props.row.status === 'Close Store' ||
                  props.row.status === 'Assigned For Last Mile' ||
                  props.row.status === 'Arrived At Branch'
                "
                block
                class="btn btn-block CloseStoreDeliveryFailed"
              >
                {{ props.row.status }}
              </button>
              <button
                v-else-if="props.row.status === 'Second Delivery'"
                block
                class="btn btn-block SecondDelivery"
              >
                {{ props.row.status }}
              </button>
              <b-button
                v-else-if="props.row.status === 'Cancelled'"
                block
                variant="danger"
              >
                {{ props.row.status }}
              </b-button>
              <b-button
                v-else-if="props.row.status === 'Generated'"
                block
                variant="warning"
              >
                Generated
              </b-button>
            </span>
            <span v-else-if="props.column.field == 'product_category'">
              {{
                props.row.product_category ? props.row.product_category : 'N/A'
              }}
            </span>
            <span v-else-if="props.column.field == 'order_type'">
              <b-button
                v-if="props.row.order_type == 'DHL'"
                variant="warning"
                block
                >{{ props.row.order_type }}</b-button
              >
              <b-button
                v-if="props.row.order_type == 'Outlet'"
                variant="info"
                block
                >{{ props.row.order_type }}</b-button
              >
              <b-button
                v-if="props.row.order_type == 'Singapore'"
                variant="success"
                block
                >{{ props.row.order_type }}</b-button
              >
              <b-button
                v-if="props.row.order_type == 'Agrofun'"
                variant="success"
                block
                >{{ props.row.order_type }}</b-button
              >
              <b-button
                v-if="props.row.order_type == 'Agrobazaar'"
                variant="success"
                block
                >{{ props.row.order_type }}</b-button
              >
            </span>
            <span v-else-if="props.column.field === 'action'">
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item @click="generateLabel(props.row)">
                  <span>Generate Label</span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="
                    updateOrderStatus(props.row.id, props.row.originalIndex)
                  "
                >
                  <span>Update Status</span>
                </b-dropdown-item>
                <b-dropdown-item @click="handleModal(props.row)">
                  <span>View Tracking</span>
                </b-dropdown-item>
                <!-- <b-dropdown-item @click="editOrder(props.row.id)">
                    <span>Edit Order</span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="cancelOrder(props.row.id, props.row.originalIndex)">
                    <span>Cancel</span>
                  </b-dropdown-item> -->
                <!-- <b-dropdown-item @click="getDriver(props.row.id)">
                  <span>Assign Driver</span>
                </b-dropdown-item> -->
              </b-dropdown>
            </span>

            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap"> Showing 1 to </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['5', '10', '20']"
                  class="mx-1"
                  @input="
                    (value) => props.perPageChanged({ currentPerPage: value })
                  "
                />
                <span class="text-nowrap"> of {{ props.total }} entries </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value) => props.pageChanged({ currentPage: value })"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </div>
    </b-card>
    <!-- Assign Driver Modal -->
    <b-modal
      id="modal-3"
      ref="modal-3"
      centered
      ok-only
      ok-title="Save"
      hide-footer
      no-close-on-backdrop
    >
      <div>
        <div class="d-flex justify-content-center mt-2">
          <h2><b>Assign Driver</b></h2>
        </div>
        <validation-provider
          #default="{ errors }"
          name="Select Driver"
          rules="required"
        >
          <select
            v-model="driverOrder.driver_id"
            class="form-control form-select rounded"
          >
            <option
              v-for="(driver, index) in driverList"
              :key="index"
              :value="driver.id"
            >
              {{ driver.name }}
            </option>
          </select>
          <small class="text-danger">{{ errors[0] }}</small>
          <div class="d-flex justify-content-center my-2">
            <b-button variant="gradient-primary" @click="assignDriver">
              SUBMIT
            </b-button>
          </div>
        </validation-provider>
      </div>
    </b-modal>
    <!-- Generate Label Modal-->
    <b-modal ref="generateLabel" hide-footer hide-title centered size="lg">
      <OrderLabel :order="generateOrderlabel" />
    </b-modal>
    <!-- Generate DHL Tracking-->
    <b-modal
      ref="generatedhltracking"
      hide-footer
      hide-title
      title="Tracking Information"
      centered
      size="lg"
    >
      <DHLTracking :shipmentid="generateDHLid" />
    </b-modal>
    <!-- Generate DHL Label-->
    <b-modal ref="generatedhllabel" hide-footer hide-title centered size="lg">
      <DHLLabel />
    </b-modal>
    <!-- Transit Route -->
    <b-modal id="modal-1" ref="modal-1" title="Change Delivery Point">
      <div class="d-flex justify-content-center align-items-center">
        <select v-model="branchId" class="form-control form-select rounded">
          <option value="" disabled>Select Destination Point</option>
          <option v-for="address in address" :key="address.id" :value="address">
            {{ address.name }} | {{ address.branch.address }}
          </option>
        </select>
      </div>
      <template #modal-footer>
        <b-button variant="primary" @click="updateBranchStatus()"
          >Confirm</b-button
        >
      </template>
    </b-modal>
    <!-- Route Plan Summary -->
    <b-modal
      ref="routePlan"
      :title="`Order Information`"
      :size="`xl`"
      hide-footer
      no-close-on-backdrop
    >
      <RoutePlanSummary :summary="this.RoutePlanSummary" />
    </b-modal>
  </div>
</template>

<script>
import 'vue-good-table/dist/vue-good-table.css';
import { VueGoodTable } from 'vue-good-table';
import { integer } from 'vee-validate/dist/rules';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import csvDownload from 'json-to-csv-export';
import { required } from '@validations';
import store from '@/store/index';
import vSelect from 'vue-select';
import axios from '@axios';
import exportFromJSON from 'export-from-json';
import OrderLabel from '../MyTask/OrderLabel.vue';
import DHLTracking from '../OrderForms/DHL/DHLTracking.vue';
import DHLLabel from '../OrderForms/DHL/DHLLabel.vue';
import RoutePlanSummary from './RoutePlanSummary.vue';

export default {
  components: {
    VueGoodTable,
    OrderLabel,
    DHLTracking,
    DHLLabel,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    RoutePlanSummary,
  },

  props: {
    orderTable: Object,
  },

  data() {
    return {
      dummyData: {},
      RoutePlanSummary: {},
      type: '',
      rangeDate: null,
      searchTerm: '',
      pageLength: 3,
      dir: false,
      currentRoute: '',
      selected: '',
      selected1: null,
      generateDHLid: '',
      generateOrderlabel: '',
      branchId: '',
      address: '',
      selectedId: [],
      selectedIndex: [],
      orderCancel: {},
      driverOrder: {},
      updateStatus: {},
      optionsStatus: {
        'In Warehouse': 'In Warehouse',
        'Arrived At Branch': 'Arrived At Branch',
        'Out For Delivery': 'Out For Delivery',
        Delivered: 'Delivered',
        'Close Store': 'Close Store',
        'Second Delivery': 'Second Delivery',
      },
      driverList: [],
      optionsCancel: {
        'Duplicate Order': 'Duplicate Order',
        "Wrong Recipient's Address": "Wrong Recipient's Address",
        'Client Not Ready for Pick Up': 'Client Not Ready for Pick Up',
        'Cancel Order': 'Cancel Order',
        'IT Testing Order': 'IT Testing Order',
      },
    };
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
    subtotal() {
      return (quantity, unit_price) => unit_price * quantity;
    },
  },
  async mounted() {
    await this.$http.get('getOutlet').then((response) => {
      this.address = response.data;
    });
  },
  methods: {
    generatedhl(id, index) {
      this.$emit('generatedhl', id, index);
    },
    selectionChanged() {
      this.selectedId = [];
      this.selectedIndex = [];
      this.$refs['my-table'].selectedRows;
      for (let x = 0; x < this.$refs['my-table'].selectedRows.length; x++) {
        this.selectedId.push(this.$refs['my-table'].selectedRows[x].id);
        this.selectedIndex.push(
          this.$refs['my-table'].selectedRows[x].originalIndex
        );
      }
    },
    updateBranchStatus() {
      const updateStatus = {};
      updateStatus.orders = this.selectedId;
      updateStatus.branch = this.branchId.branch_id;
      this.$http
        .post('transferToOtherBranch', updateStatus)
        .then((response) => {
          if (response.status === 200) {
            if (response.data.status == true) {
              this.$swal({
                title: 'Status Delivery Updated !',
                text: response.data.message,
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              });
              this.$refs['modal-1'].hide();
              this.$emit('updateOrderstatus', this.updateStatus, index);
            } else {
              this.$swal({
                title: 'Status Delivery Error !',
                text: response.data.message,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              });
            }
          }
        });
    },
    generatedhltracking(id) {
      this.generateDHLid = id.shipment_id;
      this.$refs.generatedhltracking.show();
    },
    generatedhllabel(id) {
      location.href = id;
    },
    canceldhlgenerate(dhl) {
      axios;
      this.$http.get(`dhl_cancel/${dhl.shipment_id}`).then((response) => {
        if (response.status == 200) {
          if (response.data.message == 'Shipment has already been cancelled.') {
            this.$swal({
              title: 'Cancellation Accepted !',
              text: 'Successfully cancelled the order',
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            });
          }
        }
      });
    },
    handleModal(data) {
      this.RoutePlanSummary = data;
      this.$refs.routePlan.show();
    },
    generateLabel(row) {
      this.generateOrderlabel = row;
      this.$refs.generateLabel.show();
    },
    updateOrderStatus(id, index) {
      const reasons = this.optionsStatus;
      this.$swal({
        title: 'Update Status',
        input: 'select',
        inputOptions: reasons,
        inputPlaceholder: 'Select Status',
        inputAttributes: {
          'aria-label': 'Select Status',
        },
        inputValidator: (value) => !value && 'Please give a value',
        allowOutsideClick: true,
        confirmButtonText: 'SUBMIT',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.updateStatus.order_id = id;
          this.updateStatus.status = result.value;
          this.$emit('updateOrderstatus', this.updateStatus, index);
        }
      });
    },
    editOrder(id) {
      this.$router.push(`/order-summary/${id}`);
    },
    cancelOrder(id, index) {
      const reasons = this.optionsCancel;
      this.$swal({
        title: 'Cancel Order',
        input: 'select',
        inputOptions: reasons,
        inputPlaceholder: 'Select Reason',
        inputAttributes: {
          'aria-label': 'Select Reason',
        },
        inputValidator: (value) => !value && 'Please give a value',
        allowOutsideClick: true,
        confirmButtonText: 'SUBMIT',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.orderCancel.order_id = id;
          this.orderCancel.cancel_reason = result.value;
          this.$emit('cancelOrder', this.orderCancel, index);
        }
      });
    },
    deleteDriver() {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$swal({
            icon: 'success',
            title: 'Deleted!',
            text: 'Your file has been deleted.',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          });
        }
      });
    },
    getDriver(id) {
      axios;
      this.$http
        .get('driver')
        .then((response) => (this.driverList = response.data.data));
      this.driverOrder.order_id = id;
      this.$refs['modal-3'].show();
    },

    async handleExport() {
      this.$emit('handleExport');
    },

    async handleExportReport() {
      this.$emit('handleExportReport');
    },

    assignDriver() {
      this.$http.post('assign_driver', this.driverOrder).then((response) => {
        if (response.status == 200) {
          if (response.data.status == true) {
            this.$swal({
              title: 'Driver Assigned !',
              icon: 'success',
              text: response.data.message,
              confirmButtonText: 'OK',
              customClass: {
                confirmButton: 'btn btn-success',
              },
              buttonsStyling: false,
            });
          } else {
            this.$swal({
              title: ' Error !',
              icon: 'error',
              text: response.data.message,
              confirmButtonText: 'Confirm',
              customClass: {
                confirmButton: 'btn btn-warning',
              },
              buttonsStyling: false,
            });
          }
        }
        this.$refs['modal-3'].hide();
      });
    },
    confirmText() {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$swal({
            icon: 'success',
            title: 'Deleted!',
            text: 'Your file has been deleted.',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          });
        }
      });
    },
    confirmButtonColor() {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$swal({
            icon: 'success',
            title: 'Deleted!',
            text: 'Your file has been deleted.',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          });
        } else if (result.dismiss === 'cancel') {
          this.$swal({
            title: 'Cancelled',
            text: 'Your imaginary file is safe :)',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-select.scss';

select {
  width: 120px;
  height: 40px;
}

.test {
  z-index: 10;
}

.avatar {
  vertical-align: middle;
  width: 50px;
  height: auto;
  border-radius: 50%;
}

.exportWidth {
  width: 10vw;
}

.Active {
  background-color: #6558f5;
  color: white;
}

.Assigned {
  background-color: #730fc3;
  color: white;
}

.InWarehouse {
  background-color: #2c88d9;
  color: white;
}

.InTransit {
  background-color: #f7c325;
  color: white;
}

.OutForDelivery {
  background-color: #bd34d1;
  color: white;
}

.Delivered {
  background-color: #1aae9f;
  color: white;
}

.CloseStoreDeliveryFailed {
  background-color: #d3455b;
  color: white;
}

.SecondDelivery {
  background-color: #e8833a;
  color: white;
}
</style>
